import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { getGeneralInfo, getEvents,getEventsPage,getEventsbyId } from "../../providers"
import InsideEvent from "../../components/OurEvents/InsideEvent"
import { Hero4 } from "../../components/Hero4"
import { MainLayout } from "../../components/Layouts/MainLayout"


const Evento = (props) => {
   const url =props.params
  const [datafetch,setDatafetch]= useState ([])
  const [eventsPage, setEventsPage] = useState({})
  const [generalInfo, setGeneralInfo] = useState({})

  const [eventsPageId, setEventsPageId] = useState({})
  const url2 = "https://api.itcadperu.com.pe"

   useEffect(() => {
    const fetchData = async () => {
      try {
        const _eventsPage = await getEventsPage()
        setEventsPage(_eventsPage[0])

        const _datafetch = await getEvents()
        const elemento =_datafetch.filter((item)=>item.id===parseInt(url.id))

        const _generalInfo = await getGeneralInfo()
        setGeneralInfo(_generalInfo[0])

        const _eventsPageId = await getEventsbyId(url.id)
        setEventsPageId(_eventsPageId)

     
        setDatafetch(elemento)

      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  
   return(
    <div>
      <MainLayout>
            <Hero4
        backgroundImage={eventsPage?.background_image}
        title={eventsPage?.title}
      />

      <InsideEvent 
      
      data={eventsPageId} 
      generalInfo={generalInfo}
      url={url2}
      images={eventsPageId?.event_images}
      />
      </MainLayout>
    </div>)
}

export default Evento