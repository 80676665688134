import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Title } from "../Title"
import StarRating from "./StarRating";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const TitleE=styled(Title)`
  font-size: min(max(20px, 3vw), 42px);
margin-bottom: 0.3em;

@media (max-width: 500px) {
 // font-size: 20px;
  margin-bottom: 0.5em;

}
`

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`

const FirstBlockChild= styled.div`
max-width: 1440px;
width: 100%;
position: relative;
display:flex;
flex-direction: column;
justify-self: center;
margin: 4em 0;
padding: 0 5em;

/*.carousel-container {
  padding: 0 50px; // ajusta el relleno para evitar superposición con las flechas
  position: relative;//
  overflow: hidden;//
}*/
.photos{
  position: relative;
  /*img{
  //width: 1150px;
  //height: 553px;
  width: 100%;//
  height: auto;//
  object-fit: scale-down;
  }*/
.carrusel{
img{
  object-fit: cover;
  margin: 0 100px;
  max-width: 90%;
}
.img_container{
  display: flex;
  justify-content: center;
}
.react-multiple-carousel__arrow {
    z-index: 1;
  }
.react-multiple-carousel__arrow::before {
    color:#0044B0;
    font-size: 30px;
  }
  .react-multiple-carousel__arrow {
    background:none;
  }

  .react-multi-carousel-item {
    display: flex;
  justify-content: center;
  width:100%;
  padding: 0 25px;

  }
  .react-multiple-carousel__arrow--right {
      right: -10px;
    }
    .react-multiple-carousel__arrow--left {
      left: -10px;
    }
    .react-multi-carousel-track{
      align-items:center;
    }
}}
.coursetype{
    color: #0044B0;
    font-size: 15px;
    font-weight: 500;
    padding: 3px 2px;
    background-color: #60BF94;
    border-radius: 20px;
    max-width: 100px;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }
.locationStyle{
  font-weight: bold;
 // font-size: 22px;
 font-size: min(max(14px, 2vw), 23px);

}
.dateStyle{
  font-weight: normal;
  //font-size: 18px;
  font-size: min(max(16px, 2vw), 18px);

  color:#0044B0;
  margin-bottom: 1em;
}
.text {
    padding: 0 0 0 0;
    margin-bottom: 2em;
      line-height: 160%;
      color: #000000;
      //font-size: 19px;
      font-size: min(max(14px, 2vw), 21px);

      text-align: justify;
      /*display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;*/
    }
    .question{
        //font-size: 30px;
        font-size: min(max(18px, 2vw), 30px);
        font-weight: 700;
        position: relative;
        display: flex;
        justify-content: center;
        margin: 1em 0;
        text-align: center;
    }
    .val{
        //font-size: 20px;
        font-size: min(max(16px, 2vw), 21px);

        position: relative;
        display: flex;
        justify-content: center;
        margin: 0 0 0.3em 0;

    }
    .sharediv{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        /*padding: 0 5.5em 0 0;*/
        margin: 1em 0 3em 0;
        div{
            border: 3px solid #0044B0;
            width: 300px;
        }
   }
   .share{
        color: #0044B0;
        //font-size: 28px;
        font-size: min(max(18px, 2vw), 28px);
        font-weight: 700;
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
    }
@media (max-width: 900px) {
  width:100%;

  .text {

      font-weight: normal;
      //font-size: 16px;
      line-height: 160%;
      color: #000000;
    }
    .title {

      font-weight: bold;
      //font-size: 20px;
      line-height: 26px;
      margin-bottom: 8px;
    }
}

@media (max-width: 500px) {
  margin: 2em 0;
padding: 0 2em;


/*.text {
  font-size: 14px;

}
.question{
  font-size: 20px;
}
.val{
  font-size: 16px;
}*/
.sharediv{
  div{
            border: 2px solid #0044B0;
            width: 150px;
        }
}
/*.share{
  font-size: 19px;
}

.photos{
  width: 100%;
  display: flex;//
  justify-content: center; // 
  align-items: center;//
  img{
  width: 269px;
  height: 190px;
  left: -3.4em;
  position: relative;
  }
}*/
}

`
const ContentSocialNetwork = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 0 3em;
  /*.link {
    width: 100%;
  }*/
  .social{
    margin: 1em 0 3em 0;
    width: 300px;
    display: flex;
    justify-content: space-around;
   

  a { 
    width: 10px;

    img {
    width: 40px;

    :hover{

  border: none;
  color: #FFFFFF;
  transform: scale(1.1);
  will-change: transform;

}

  }
  }


  }
  @media (max-width: 780px) {
    width: 100%;
    justify-content: center;
    /*padding-right: 0;
    padding-left: 30px;*/
    margin-bottom: 30px;
    border-right: none;
    left: -10px;
    .social{
      a{
        img{
          width: 30px;
        }
      }
    }
  } ;
`
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1300, min: 900 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const InsideEvent = ({data, generalInfo,images}) => {
   
  const paragraphs = data?.page_content ? data.page_content.split("/") : [];


    return (
    ( data ?  
    
        <Container>
        <FirstBlockChild>
        <div className="coursetype">Eventos</div>
        <TitleE>{data?.title}</TitleE>
        <div className="locationStyle">{data?.location}</div>
        <div className="dateStyle">{data?.date}</div>
        <div className="text">{paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
            ))}
        </div>
        
        <div className="photos">
        <Carousel 
            className="carrusel"
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}

        keyBoardControl={true}
        transitionDuration={500}
        ssr={true}
        containerClass="carousel-container"
        >
       {Array.isArray(images) && images.map((slider, index) => (       
        <div className="img_container" key={index}>
            {slider?.image &&  <img src={slider?.image} />}
        </div>
       ))}

{/*

{data?.map(slider => (       
                  <div>
                  {slider?.event_images &&  <img src={url + slider?.event_images} alt="person-galery"/>}
                        
                        <div>{slider?.event_images}</div>
                        
                  </div>
           ))}
           
        <img src="/images/ChooseUs/carouselimage.png"/>
        <img src="/images/ChooseUs/itcad-success.png"/>*/}
</Carousel>


        {/*<img src="/images/ChooseUs/carouselimage.png"/>
        <img src="/images/ChooseUs/itcad-success.png"/>*/}
        
        </div>

        <div className="question">¿Participaste en el evento?</div>
        <div className="val">Déjanos una valoración</div>
        <StarRating />
        <div className="sharediv"><div></div></div>
        <div className="share">Comparte tu experiencia aquí</div>     

        <ContentSocialNetwork>
            <div className="social">
            <a
                className="link"
                href={generalInfo?.facebook_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/fb.svg" alt="facebook de empresa dedicada a educar a especialistas 3d" />
            </a>

            <a
                className="link"
                href={generalInfo?.instagram_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/ig.svg" alt="instagram de empresa dedicada a educar a especialistas 3d" />
            </a>

            <a
                className="link"
                href={generalInfo?.tiktok_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/tiktok.svg" alt="tiktok de empresa dedicada a educar a especialistas 3d" />
            </a>

            <a
                className="link"
                href={generalInfo?.linkedin_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/linkedin.svg" alt="linkedin de empresa dedicada a educar a especialistas 3d" />
            </a>

            <a
                className="link"
                href={generalInfo?.youtube_link}
                target="_blank"
            >
                <img className="img2" src="/images/Contact/youtube.svg" alt="youtube de empresa dedicada a educar a especialistas 3d" />
            </a>
            </div>
        </ContentSocialNetwork>
        </FirstBlockChild>
    </Container>
    :
    <div>Loading...</div>
    )
    )
    }
    export default InsideEvent