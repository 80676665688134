
import styled from "styled-components"

import React, { useState } from "react";

const Stars = styled.div`
display: flex;
position: relative;
justify-content: center;
button {
    font-size: 3em;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .on {
    color: #F2AE2C;
  }
  .off {
    color: #ccc;
  }

`

const StarRating = () => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    return (
      <Stars>
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= (hover || rating) ? "on" : "off"}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
      </Stars>
    );
  };

export default StarRating;